/* * * * * * * * * * * * */
/* Initialize functions  */
/* * * * * * * * * * * * */

  initialize = {

    docReady: function() {

      /** Custom **/

        // ...

      /* * * * * * */

      /** Default **/
        fx_messages.init(); // base.js
        fx_modals.init();
        fx_lightrequest.init();
        fx_datepicker.init();
        fx_symmetrical.make();
        fx_signinModal.init();
      /* * * * * * */
    },

    winLoad: function() {

      /** Custom **/

        // ...
        fx_signinModal.load();
        fx_modals.load();

      /* * * * * * */

      /** Default **/

         // ...

      /* * * * * * */

    },

    docReady_winLoad: function() {

      /** Custom **/

        // ...

      /* * * * * * */

      /** Default **/
        fx_base.environment(); // base.js
        fx_formFields.validate(); // base.js
      /* * * * * * */
    },

    resize: function() {

      /** Custom **/

        // ...

      /* * * * * * */

      /** Default **/

         // ...

      /* * * * * * */
    }

  }

/* * * * * * * * * * * * * * * * * */

/* * * * * * * * * * * * * * * * * */
/* Do not change or add nothing!!! */
/* * * * * * * * * * * * * * * * * */

  $(document).ready(function() {
    initialize.docReady();
    initialize.docReady_winLoad();
    initialize.resize();
    $(window).resize(function() {
      initialize.resize();
    });
  });
  $(window).load(function() {
    initialize.winLoad();
    initialize.docReady_winLoad();
    initialize.resize();
    $(window).resize(function() {
      initialize.resize();
    });
  });

/* * * * * * * * * * * * * * * * * */
